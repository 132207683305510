import React, { useEffect } from 'react';
import { Container } from '../General/Container.styles';
import { IoMdArrowDroprightCircle } from 'react-icons/io';
import { Content, HireButton } from './About.styles';
import { Text } from '../General/Text.styles';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const About = () => {
  const contentVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 0.3,
      },
    },
  };

  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <Container id='about'>
      <Text>
        <Content
          className='About'
          ref={ref}
          initial='hidden'
          animate={controls}
          variants={contentVariants}
        >
          <h1>about</h1>
          <p>
            Hi my name is Rehd and{' '}
            <span>I love developing things at front-end</span>. I was introduced
            to the basic HTML and CSS Codes way back 2010 when Friendster and
            Facebook still uses them for customizing profiles and welcome pages.
            With <span>little intrinsic imagination and creativity</span>, web
            development slowly became my interest and hobby.{' '}
          </p>
          <p>
            I decided to pursue this technological passion by obtaining diploma
            in web design and development. Despite of being a fresh graduate, I
            was able to{' '}
            <span>
              accomplish front-end projects, adding first-hand experiences to my
              acquired academic knowledge
            </span>
            . Currently, I am developing React websites as free-lancer.
          </p>
          <div>
            <p>Here are some skills I’ve been working with recently:</p>
            <ul>
              <li>
                <IoMdArrowDroprightCircle />{' '}
                <p className='tags'>HTML &amp; CSS</p>
              </li>
              <li>
                <IoMdArrowDroprightCircle />{' '}
                <p className='tags'>JavaScript (ES6+)</p>
              </li>
              <li>
                <IoMdArrowDroprightCircle /> <p className='tags'>React.js</p>
              </li>
              <li>
                <IoMdArrowDroprightCircle /> <p className='tags'>WordPress</p>
              </li>
              <li>
                <IoMdArrowDroprightCircle />{' '}
                <p className='tags'>Laravel (PHP)</p>
              </li>
              <li>
                <IoMdArrowDroprightCircle /> <p className='tags'>Photography</p>
              </li>
            </ul>
          </div>
          <div>
            <HireButton
              to='contact'
              smooth={true}
              duration={true}
              spy={true}
              exact={true}
              offset={-80}
            >
              <p className='hire'>Hire me</p>
            </HireButton>
          </div>
        </Content>
      </Text>
    </Container>
  );
};

export default About;
