export const projectOne = {
  id: '1',
  imgStart: 'start',
  title: 'Robert Stirbu Website',
  imgUrl: require('../../images/rsw.jpg').default,
  description:
    'A capstone project developed by Dream Media Team that consist of Nina Murray, William He and myself. This website is an e-commerce website using WordPress (built from scratch) for a client named Robert Stirbu; where he can sell his masterpiece photography to customers across US and Canada.',
  tags: 'WordPress PHP Woocommerce mySQL JavaScript',
  siteUrl: 'https://rnsprints.com/',
  hasButton: true,
  hasWebsite: 'display',
  hasGit: 'none',
};

export const projectTwo = {
  id: '2',
  imgStart: 'finish',
  title: 'Cryptocurrency Tracker',
  imgUrl: require('../../images/crypto.png').default,
  description:
    'A React project I made that tracks the price of the crypto coins. This project can also search for a coin that they want and has a feature of a Load More button. This project aims to ensure that my comfortability of using React is set to high.',
  tags: 'React JSX CSS HTML Axios Coingecko',
  siteUrl: 'https://github.com/hisnameisrehd/react-crypto-tracker',
  hasButton: true,
  githubLink: 'https://github.com/hisnameisrehd/react-crypto-tracker',
  hasWebsite: 'none',
  hasGit: 'display',
};

export const projectThree = {
  id: '3',
  imgStart: 'start',
  title: '3Ds (In Progress)',
  imgUrl: require('../../images/3ds.jpg').default,
  description:
    'A website that aims to book appointments and provide details to a customer who is currently looking for a company that has the ability to detail or metal polish cars or trucks. This is my first professional project created',
  tags: 'React JavaScript HTML CSS',
  siteUrl: 'https://www.facebook.com/3dsdetailingandmetalpolishing/',
  hasButton: 'none',
};
